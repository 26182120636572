import React from "react";
import styles from "../../scss/modules/project_details.module.scss"

export default (props) => {

  const location = props.location;
  const client = props.client;
  const services = props.services;
  const completionDate = props.completionDate;

  let locationListItem = '';

  if (location) {
    locationListItem = <li>
      <label className={styles.project_details__list__label} htmlFor="location">Location: </label>
      <span id="location">{location}</span>
    </li>
  }

  return (
    <aside className={styles.project_details}>
      <div className={styles.project_details__inner}>
        <h2 className={styles.heading}>Project Details</h2>
        <div className={styles.project_details__list}>
          <ul>
            {locationListItem}
            <li>
              <label className={styles.project_details__label} htmlFor="client">Client: </label>
              <span id="client">{client}</span>
            </li>
            <li>
              <label className={styles.project_details__label} htmlFor="services">Services: </label>
              <span id="services">{services}</span>
            </li>
            { completionDate &&
              <li>
              <label className={styles.project_details__label} htmlFor="completionDate">Date: </label>
              <span id="completionDate">{completionDate}</span>
              </li>
            }
          </ul>
        </div>
      </div>
    </aside>
  )
}
