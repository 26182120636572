import React from "react"
import { graphql } from "gatsby"
import paragraphs from "lines-to-paragraphs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Details from "../components/projects/details"

import HeaderStyles from "../scss/layouts/project_header.module.scss"
import ProjectStyles from "../scss/layouts/project_content.module.scss"
import Arrow from "../images/svg/arrow-right.inline.svg"

export default ({ data }) => {
  const project = data.markdownRemark;
  console.log(project.fields.slug);
  const categoryRegEx = /^\/projects\/([a-z0-9-]+)\/[\s\S]*$/;
  const categoryUrl = categoryRegEx.exec(project.fields.slug)[1];
  const introContent = paragraphs(project.frontmatter.intro);

  return (
    <Layout>
      <SEO
        title={project.frontmatter.title}
        description={project.frontmatter.intro }
        pathname={project.fields.slug}
      />

        <section className={HeaderStyles.project_header}>
            <div className={HeaderStyles.project_header__inner}>

                <div className={HeaderStyles.project_header__back_link}>
                  <a href={`/projects/${categoryUrl}`}><Arrow />Back to all {categoryUrl.replace('-', ' ')} projects</a>
                </div>
                <h1>{project.frontmatter.name}</h1>
            </div>
        </section>
        <div className={ProjectStyles.project_content}>
          <div className={ProjectStyles.project_content__intro}>
            <div className={ProjectStyles.project_content__intro__inner}>
              <div dangerouslySetInnerHTML={{ __html: introContent  }} className={ProjectStyles.project_content__intro__content} />
              <Details location={project.frontmatter.location}
                       client={project.frontmatter.client}
                       services={project.frontmatter.services}
                       completionDate={project.frontmatter.completion_date} />
            </div>
          </div>
          <div className={ProjectStyles.project_content__main}>
            <div dangerouslySetInnerHTML={{ __html: project.html }} />
          </div>
        </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        template
        location
        client
        services
        completion_date
        intro
      }
      html
      fields {
        slug
      }
    }
  }
`
